<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :fields="fields"
        :items="pathologies"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Patologías por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #id="{ item }">
          <td>
            {{ item.id_pathology }}
          </td>
        </template>

        <template #pathology_name="{ item }">
          <td>
            {{ item.pathology_name }}
          </td>
        </template>

        <template #acciones="{ item }">
          <td>

            <CButton
              color="danger"
              shape="pill"
              size="sm"
              @click="deleteButton(item)"
            >
              Eliminar
            </CButton>

            <CModal
              id="modalElim"
              title="Eliminar patologia"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar la patología: 
                "{{ pathology.pathology_name }}" ? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  style="margin-right:1em;"
                  @click="deletePathology(pathology.id_pathology)"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="deleteModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import {getPathologiesData, checkUser} from '../utils/utils';
export default {
  name: "TablaPatologias",
  props: {
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "pathology_name",
            label: "Nombre de la patología"
          },
          {
            key: "pathology_description",
            label: "Descripción de la patología"
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }
        ];
      },
    },
    caption: {
      type: String,
      default: "Table",
    }
  },
  data() {
    return {
      deleteModal: false,
      pathology: {},
      pathologies: [],
    }
  },
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getPathologies();
    },
     getPathologies() {
      let data = getPathologiesData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let element = {};
        let datos=[];
        result.pathologies.forEach(patologia => {
          element = {};
          element.id_pathology = patologia.id_pathology;
          element.pathology_name = patologia.pathology_name;
          element.pathology_description = patologia.pathology_description;
          datos.push(element);
        });
        this.pathologies = datos;
      });
    },
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} pathology - La patologia sobre el que se ha llamado al evento
     */
    deleteButton(pathology) {
      this.pathology = pathology;
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará la patología, mandando por la URL el id
     * 
     * @param {number} id - El id de la patología a eliminar
     */
    deletePathology(id) {
      const token = localStorage.token;
      axios.delete(
        config.BACK_IP + "/pathology",
        {
          headers: {
            token: token,
          }, 
          data: {
            pathology_id: id,
          }
        }
      )
      .then(() => {
        this.getPathologies();
      })
      .catch((error) => {
        if(error.response) {
          if(error.response.status === 401) {
            localStorage.removeItem("token");
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/pages/404";
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
      this.deleteModal = false;
    },
  }
}
</script>